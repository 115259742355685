import BasePlugin from '../BasePlugin'

export default class SendForecastReportToApprovalCommand extends BasePlugin {
  async execute () {
    let forecastReportId = this.context.getModel().id
    let status = this.context.getModel().attr_4025_

    if (status !== 2) {
      this.context.$msgbox({
        title: 'Согласование отчета прогноза',
        message: 'Вы действительно хотите отправить на согласование данный отчет-прогноз ?',
        showCancelButton: true,
        confirmButtonText: 'Да',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/ReportForecastProject/SendForecastReportToApprovalCommand`,
              { 'forecastReportId': forecastReportId },
              {
                headers: {
                  'Content-Type': 'application/json'
                }
              }
            ).then((response) => {
              this.context.$msgbox({
                type: 'info',
                message: 'Отчет-прогноз отправлен на согласование'
              })
            })
          }
          done()
        }
      })
      this.context.getCard().$emit('cancelChanges')
    } else {
      this.context.$msgbox({
        type: 'info',
        message: 'Отчет-прогноз уже согласован!'
      })
      done()
    }
  }
}
